.pagination {
  margin: 40px auto;
  @include flexCenter;
  list-style: none;
  outline: none;
  li {
    @include flexAlignCenter;
    height: 40px;
    width: 40px;
    max-width: 40px;

    border: 1px solid $black4;
    cursor: pointer;
    margin: 0 2px;
  }
  li.active {
    border: 1px solid $red;
    background: $red;
    &:hover {
      background: $red-hover;
    }
    a {
      color: white;
    }
  }
  li a {
    @include flexCenter;
    height: 100%;
    width: 100%;
    padding: 0;
    font-size: 16px;
    font-weight: 500;
    color: $black1;
    outline: none;
  }
}

.pagination > li:not(.active) :hover {
  background: $black5;
}

@media screen and (max-width: 450px) {
  .pagination li a {
    font-size: 15px;
  }
}

@media screen and (max-width: 360px) {
  .pagination {
    li {
      height: 34px;
      max-width: 34px;
    }
  }
}
