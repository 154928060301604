.forget-page-content {
  @include flexCenter;
  position: relative;
  width: 550px;
  height: 350px;
  background: white;
  border-radius: 10px;
  border: 1px solid $black5;
  margin: 150px auto 0;
}

.interface {
  @include flexCenter;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.interface-one {
  p {
    font-size: 21px;
    font-weight: 600;
    color: $black1;
    text-align: center;
  }
  .user-email {
    position: relative;
    display: block;
    width: 65%;
    margin-top: 30px;
    margin-bottom: 50px;
    input {
      width: 100%;
      height: 45px;
      font-size: 16px;
      font-weight: 400;
      line-height: 51px;
      box-sizing: border-box;
      padding-left: 10px;
    }
    input::placeholder {
      color: $black2;
    }
    .error-msg {
      margin-top: 5px;
      color: $red;
    }
  }
  .send-btn {
    @include btn;
    width: 250px;
    height: 45px;
    margin: 0 auto;
    background: $red;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 600;
    &:disabled {
      cursor: auto;
      opacity: 0.3;
    }
  }
}

.interface-two {
  .img-wr {
    width: 82px;
    margin-bottom: 25px;
  }
  .success-title {
    font-size: 21px;
    font-weight: 600;
    text-align: center;
  }
  .success-info {
    width: 95%;
    height: 52px;
    font-size: 19px;
    font-weight: 500;
    color: rgba(116, 116, 116, 1);
    line-height: 26px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 420px) {
  .forget-page-content {
    width: 400px;
    height: 300px;
    margin: 100px auto 0;
  }
  .interface-one {
    p {
      font-size: 20px;
    }
    .user-email {
      width: 90%;
    }
  }
  .interface-two {
    .img-wr {
      width: 70px;
      img {
        width: 70px;
      }
      margin-bottom: 20px;
    }
    .success-title {
      font-size: 20px;
    }
    .success-info {
      width: 90%;
      font-size: 16px;
    }
  }
}

@media screen and (max-width: 380px) {
  .forget-page-content {
    width: 350px;
    height: 280px;
  }
}

@media screen and (max-width: 320px) {
  .forget-page-content {
    width: 310px;
    height: 270px;
  }
  .interface-one {
    p {
      font-size: 18px;
    }
    .user-email {
      width: 93%;
    }
    .send-btn {
      width: 220px;
    }
  }
  .interface-two {
    .img-wr {
      width: 60px;
      img {
        width: 60px;
      }
      margin-bottom: 20px;
    }
    .success-title {
      font-size: 18px;
    }
    .success-info {
      width: 95%;
      font-size: 14px;
    }
  }
}
