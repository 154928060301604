@keyframes ldio-nxfauetx28e {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ldio-nxfauetx28e div {
  box-sizing: border-box !important;
}
.ldio-nxfauetx28e.white > div {
  border-color: white transparent white transparent;
}
.ldio-nxfauetx28e > div {
  position: absolute;
  width: 72px;
  height: 72px;
  top: 14px;
  left: 14px;
  border-radius: 50%;
  border: 8px solid #000;
  border-color: $red transparent $red transparent;
  animation: ldio-nxfauetx28e 1s linear infinite;
}
.ldio-nxfauetx28e > div:nth-child(2) {
  border-color: transparent;
}
.ldio-nxfauetx28e > div:nth-child(2) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}
.ldio-nxfauetx28e.white > div:nth-child(2) div:before,
.ldio-nxfauetx28e.white > div:nth-child(2) div:after {
  background: white;
  box-shadow: 0 64px 0 0 white;
}
.ldio-nxfauetx28e > div:nth-child(2) div:before,
.ldio-nxfauetx28e > div:nth-child(2) div:after {
  content: "";
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  top: -8px;
  left: 24px;
  background: $red;
  border-radius: 50%;
  box-shadow: 0 64px 0 0 $red;
}

.ldio-nxfauetx28e.white > div:nth-child(2) div:after {
  box-shadow: 64px 0 0 0 white;
}
.ldio-nxfauetx28e > div:nth-child(2) div:after {
  left: -8px;
  top: 24px;
  box-shadow: 64px 0 0 0 $red;
}
.loadingio-spinner-dual-ring {
  width: 22px;
  height: 22px;
  display: inline-block;
  overflow: hidden;
  background: transparent;
  margin-left: 7px;
}
.ldio-nxfauetx28e {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.24);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-nxfauetx28e div {
  box-sizing: content-box;
}

// input, textarea, label --------------------------------------------------
::-webkit-input-placeholder {
  color: #d1d1d1;
}

::-moz-placeholder {
  color: #d1d1d1;
}

:-ms-input-placeholder {
  color: #d1d1d1;
}

::-ms-input-placeholder {
  color: #d1d1d1;
}

::placeholder {
  color: #d1d1d1;
}

label {
  font-size: 0.8rem;
  color: #9e9e9e;
}

input:not([type]),
input[type="text"]:not(.MuiInputBase-input),
input[type="password"]:not(.MuiInputBase-input),
input[type="email"]:not(.MuiInputBase-input),
input[type="url"]:not(.MuiInputBase-input),
input[type="tel"]:not(.MuiInputBase-input),
input[type="number"]:not(.MuiInputBase-input),
input[type="search"]:not(.MuiInputBase-input),
textarea {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #9e9e9e;
  border-radius: 0;
  outline: none;
  height: 3rem;
  width: 100%;
  font-size: 16px;
  margin: 0 0 8px 0;
  padding: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-transition: border 0.3s, -webkit-box-shadow 0.3s;
  transition: border 0.3s, -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s, border 0.3s;
  transition: box-shadow 0.3s, border 0.3s, -webkit-box-shadow 0.3s;
}

input:not([type]):disabled,
input:not([type])[readonly="readonly"],
input[type="text"]:disabled,
input[type="text"][readonly="readonly"],
input[type="password"]:disabled,
input[type="password"][readonly="readonly"],
input[type="email"]:disabled,
input[type="email"][readonly="readonly"],
input[type="url"]:disabled,
input[type="url"][readonly="readonly"],
input[type="tel"]:disabled,
input[type="tel"][readonly="readonly"],
input[type="number"]:disabled,
input[type="number"][readonly="readonly"],
input[type="search"]:disabled,
input[type="search"][readonly="readonly"],
textarea:disabled,
textarea {
  color: rgba(0, 0, 0, 0.42);
  border-bottom: 1px dotted rgba(0, 0, 0, 0.42);
}

input:not([type]):disabled + label,
input:not([type])[readonly="readonly"] + label,
input[type="text"]:disabled + label,
input[type="text"][readonly="readonly"] + label,
input[type="password"]:disabled + label,
input[type="password"][readonly="readonly"] + label,
input[type="email"]:disabled + label,
input[type="email"][readonly="readonly"] + label,
input[type="url"]:disabled + label,
input[type="url"][readonly="readonly"] + label,
input[type="tel"]:disabled + label,
input[type="tel"][readonly="readonly"] + label,
input[type="number"]:disabled + label,
input[type="number"][readonly="readonly"] + label,
input[type="search"]:disabled + label,
input[type="search"][readonly="readonly"] + label,
textarea:disabled + label,
textarea + label {
  color: #0000006b;
}

input:not([type]):focus:not([readonly]):not(.MuiInputBase-input),
input[type="text"]:focus:not([readonly]):not(.MuiInputBase-input),
input[type="password"]:focus:not([readonly]):not(.MuiInputBase-input),
input[type="email"]:focus:not([readonly]):not(.MuiInputBase-input),
input[type="url"]:focus:not([readonly]):not(.MuiInputBase-input),
input[type="tel"]:focus:not([readonly]):not(.MuiInputBase-input),
input[type="number"]:focus:not([readonly]):not(.MuiInputBase-input),
input[type="search"]:focus:not([readonly]):not(.MuiInputBase-input) {
  border-bottom: 1px solid $black3;
  -webkit-box-shadow: 0 1px 0 0 $black3;
  box-shadow: 0 1px 0 0 $black3;
}

input:not([type]):focus:not([readonly]) + label,
input[type="text"]:focus:not([readonly]) + label,
input[type="password"]:focus:not([readonly]) + label,
input[type="email"]:focus:not([readonly]) + label,
input[type="url"]:focus:not([readonly]) + label,
input[type="tel"]:focus:not([readonly]) + label,
input[type="number"]:focus:not([readonly]) + label,
input[type="search"]:focus:not([readonly]) + label,
textarea:focus:not([readonly]) + label {
  color: #9e9e9e;
  transform: translateY(-14px) scale(0.8);
  transform-origin: 0 0;
  .validation {
    opacity: 0;
    transition: opacity 0.2s ease-in;
  }
}

input:not([type]) + label:after,
input[type="text"] + label:after,
input[type="password"] + label:after,
input[type="email"] + label:after,
input[type="tel"] + label:after,
input[type="number"] + label:after,
input[type="search"] + label:after,
textarea + label:after {
  display: block;
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  opacity: 0;
  -webkit-transition: 0.2s opacity ease-out, 0.2s color ease-out;
  transition: 0.2s opacity ease-out, 0.2s color ease-out;
}

.input-field {
  position: relative;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.input-field > label {
  color: #9e9e9e;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 1rem;
  cursor: text;
  -webkit-transition: color 0.2s ease-out, -webkit-transform 0.2s ease-out;
  transition: color 0.2s ease-out, -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out, color 0.2s ease-out;
  transition: transform 0.2s ease-out, color 0.2s ease-out,
    -webkit-transform 0.2s ease-out;
  -webkit-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
  text-align: initial;
  -webkit-transform: translateY(12px);
  transform: translateY(12px);
}

.input-field > label:not(.label-icon).active {
  -webkit-transform: translateY(-14px) scale(0.8);
  transform: translateY(-14px) scale(0.8);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  .validation {
    opacity: 0;
    transition: opacity 0.2s;
  }
}

input[type="email"] + label,
input[type="number"] + label,
input[type="password"] + label,
input[type="search"] + label,
input[type="tel"] + label,
input[type="text"] + label,
textarea + label {
  width: 100%;
}

input:-webkit-autofill {
  // box-shadow: inset 0 0 0 30px white !important;
  // -webkit-box-shadow: inset 0 0 0 30px white !important; /* Цвет фона */
  // -webkit-text-fill-color: $black !important; /* цвет текста */
  // color: $black !important; /* цвет текста */
  transition: background-color 5000s ease-in-out 0s;
}

textarea {
  width: 100%;
  height: 3rem;
  background-color: transparent;
  overflow: auto;
}

.button {
  position: relative;
  width: 50px;
  height: 24px;
  // overflow: hidden;
}

.button.r,
.button.r .layer {
  border-radius: 100px;
}

.checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.knobs,
.layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.knobs {
  z-index: 2;
}

.layer {
  width: 100%;
  background-color: #fcebeb;
  transition: 0.3s ease all;
  z-index: 1;
}
.button .knobs:before {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 50%;
  transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
}

.button .checkbox:checked + .knobs:before {
  content: "";
  left: 30px;
  background-color: white;
}

.button .checkbox:checked ~ .layer {
  background-color: $red;
}

.button .knobs,
.button .knobs:before,
.button .layer {
  transition: 0.3s ease all;
}
//--------------------------------------------------

.hide {
  display: none !important;
  pointer-events: none !important;
}

.hidden {
  opacity: 0 !important;
  pointer-events: none;
}

.loader-wr {
  margin: 10% auto 120px;
}

.no-scroll {
  overflow-y: hidden;
}

.unselectable {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.mobile {
  display: none !important;
}

@include _767 {
  .mobile {
    display: block !important;
  }
  .pc {
    display: none !important;
  }
}
