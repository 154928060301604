$black1: rgb(45, 49, 51);
//title / text

$black2: rgb(96, 100, 102);
//text

$black3: rgb(150, 152, 153);
// auxiliary text

$black4: rgb(202, 203, 204);
// rgba(202,203, 204, 1)

$black5: rgb(238, 238, 238);
// cut-off rule

$black6: rgb(242, 244, 245);
// background

$black7: rgba(247, 247, 247, 1);
// background

$red: rgb(245, 78, 81);
// Brand color / price

$blue: rgb(3, 169, 250);
// Auxiliary color / link

$blue2: rgba(9, 82, 155, 1);

$red-hover: rgb(238, 117, 119);
