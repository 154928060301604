// Basic styles
* {
  margin: 0;
  padding: 0;
  font-family: SuisseIntl, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
*,
:after,
:before {
  box-sizing: inherit;
}

html,
body {
  line-height: 1.5;
  font-weight: 400;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  ::-webkit-scrollbar {
    width: 10px;
    height: 6px;
  }
  scrollbar-width: auto;
  scrollbar-color: #a5a2a2 #f7efef;

  ::-webkit-scrollbar-track {
    background: #f7efef;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background: #a5a2a2;
    border-radius: 6px;
  }
}

ol li,
ul li {
  list-style-type: none;
}

h1,
h2,
h3 {
  color: $black1;
}

a {
  color: $blue2;
  text-decoration: none;
}

@media screen and (max-width: 767px) {
  html,
  body {
    ::-webkit-scrollbar {
      width: 10px;
      height: 5px;
    }
  }
}

@media screen and (max-width: 500px) {
  html,
  body {
    ::-webkit-scrollbar {
      height: 3px;
    }
  }
}
