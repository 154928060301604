.account-page-wr {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-rows: minmax(190px, auto);
  grid-gap: 20px;
  max-width: 1280px;
  width: 100%;
  margin: 20px auto 100px;
  padding: 0 50px;
  @include _767 {
    padding: 0;
    margin-bottom: 70px;
    width: 97%;
  }
}

.account-panel {
  grid-column: 1/4;
  transition: all 0.5s ease-out;
  @include _1060 {
    grid-column: 1/13;
    grid-row: 1/2;
  }
}

.main-content {
  grid-column: 4/13;
  @include _1060 {
    grid-column: 1/13;
    grid-row: 2;
  }
}

// Left panel
.user-card-wr {
  position: relative;
  background: white;
  padding: 20px 20px 25px;
  border-radius: 10px;
  .wr p,
  .wr span {
    display: block;
    text-align: center;
  }
  .user-name {
    font-size: 18px;
    margin-top: 12px;
  }
  p,
  span {
    font-size: 14px;
    line-height: 17px;
    color: $black1;
  }
  p {
    font-weight: 600;
  }
  span {
    font-weight: 700;
  }

  .balance-info {
    margin-top: 25px;
    li {
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;
      p:first-child {
        color: $black2;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

// // Section Content
// .account-wr {
//   position: relative;
//   background: white;
//   padding: 16px 0 30px;
//   box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.1);
//   box-sizing: border-box;
//   transition: all 0.5s ease-out;
//   border-radius: 10px;
//   height: 100%;
//   h1,
//   h2 {
//     padding: 0 20px;
//     font-size: 16px;
//     line-height: 19px;
//     font-weight: 700;
//     color: $black1;
//   }
//   .available-wr {
//     @include flexAlignCenter;
//     padding: 20px 20px 10px;
//     font-size: 14px;

//     span {
//       display: inline-block;
//       font-size: 24px;
//       font-weight: 600;
//       color: $red;
//       margin-left: 12px;
//     }
//   }
//   .title-wr {
//     @include flexSpaceBetween;
//     a {
//       font-size: 14px;
//       font-weight: 600;
//       color: $red;
//       padding: 0 20px;
//     }
//   }

//   @include _767 {
//     padding: 16px 0;
//     h1,
//     h2 {
//       padding: 0 12px;
//     }
//   }
// }

// .payment-settings-wr {
//   position: relative;
//   display: block;
//   padding: 0 20px 45px;
//   margin-bottom: 25px;
//   border-bottom: 1px solid $black5;

//   .sep {
//     position: absolute;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     width: 100%;
//     height: 20px;
//     background: #f2f4f5;
//   }

//   h2 {
//     padding: 0;
//   }

//   .title-wr {
//     grid-column: 1/3;
//     @include flexSpaceBetween;
//     align-items: center;
//     margin-bottom: 25px;
//   }
//   .wr {
//     display: flex;
//     flex-direction: column;
//   }
//   .edit-btn {
//     @include btn;
//     align-self: center;
//     width: 50px;
//     height: 28px;
//     border-radius: 4px;
//     background-color: $red;
//     color: white;
//     font-size: 14px;
//     font-weight: 500;
//     margin-bottom: 8px;
//   }

//   .changeStatus {
//     font-size: 10px;
//     font-weight: 500;
//     line-height: 12px;
//     text-align: center;

//     &.active {
//       color: $red;
//       text-decoration: underline;
//       cursor: pointer;
//     }
//   }

//   .bank-info-wr,
//   .alipay-info-wr {
//     display: grid;
//     grid-template-columns: 70px 1fr 70px;
//     align-items: center;
//     row-gap: 12px;
//     column-gap: 25px;

//     .img-wr {
//       @include flexCenter;
//       img {
//         width: 100%;
//         height: auto;
//       }
//     }

//     .label-wr {
//       display: grid;
//       grid-template-columns: 1fr 1fr;
//       row-gap: 10px;
//       column-gap: 20px;
//       .label {
//         font-size: 14px;
//         font-weight: 600;
//         color: $black1;
//         span {
//           color: $black3;
//         }
//       }
//     }
//   }
//   .bank-info-wr {
//     .img-wr {
//       width: 40px;
//     }
//   }
//   .bd {
//     padding-bottom: 20px;
//     margin-bottom: 20px;
//     border-bottom: 1px solid $black5;
//   }

//   @include _600 {
//     .title-wr {
//       margin-bottom: 15px;
//     }
//     .wr {
//       flex-direction: row;
//       align-items: center;
//     }
//     .edit-btn {
//       align-self: flex-start;
//       margin-bottom: 0;
//       margin-right: 12px;
//     }
//     .changeStatus {
//       font-size: 12px;
//     }

//     .bank-info-wr,
//     .alipay-info-wr {
//       grid-template-columns: 60px 1fr;
//       .img-wr {
//         grid-row: 1/3;
//       }
//       .label-wr {
//         grid-template-columns: 1fr;
//         column-gap: 0px;
//       }
//     }
//     .bank-info-wr {
//       padding-bottom: 15px;
//       margin-bottom: 15px;
//     }
//   }

//   @include _420 {
//     padding: 0 16px 16px;
//     .edit-btn {
//       height: 26px;
//       width: 46px;
//     }
//     .bank-info-wr,
//     .alipay-info-wr {
//       column-gap: 16px;
//       .label-wr {
//         row-gap: 8px;
//         .label {
//           font-size: 12px;
//         }
//       }
//     }
//   }
//   @include _320 {
//     .bank-info-wr,
//     .alipay-info-wr {
//       grid-template-columns: 50px 1fr;
//     }
//   }
// }

// .list-wr {
//   @include smoothAnimation;
//   margin-top: 15px;
//   @include _767 {
//     opacity: 1;
//     animation: unset;
//     margin-top: 8px;
//   }
// }
// .list-item {
//   display: grid;
//   grid-template-columns: 1fr 1fr 1fr;
//   grid-auto-rows: auto;
//   align-content: center;
//   column-gap: 30px;
//   row-gap: 8px;
//   padding: 10px 0;
//   padding-left: 20px;
//   &.col-5 {
//     grid-template-columns: repeat(5, 1fr);
//   }
//   &.col-6 {
//     grid-template-columns: repeat(6, 1fr);
//   }

//   p {
//     align-self: center;
//     @include text-2;
//     font-size: 12px;
//     font-weight: 500;
//     color: $black2;
//   }
//   .store-name {
//     @include text-2;
//   }

//   .comment {
//     grid-column: 1/6;
//     span {
//       margin-right: 8px;
//       font-size: 12px;
//       color: $black3;
//     }
//   }

//   .view-btn {
//     @include flexCenter;
//     height: 30px;
//     width: 100%;
//     max-width: 90px;
//     border: 1px solid $red;
//     border-radius: 4px;
//     color: $red;
//     cursor: pointer;
//   }

//   &:first-child {
//     background: $black6;
//     p {
//       font-weight: 700;
//       color: $black1;
//     }
//   }
//   &:nth-child(n + 3):nth-child(odd) {
//     background: $black7;
//   }

//   .satus-def {
//     text-transform: capitalize;
//   }
//   .available,
//   .cashed {
//     color: rgba(13, 202, 154, 1);
//   }
//   .canceled {
//     color: rgba(245, 78, 81, 1);
//   }
//   .pending {
//     color: rgba(255, 153, 0, 1);
//   }

//   @include _767 {
//     &:first-child {
//       display: none;
//     }

//     grid-template-columns: 1fr 1fr;
//     grid-template-rows: 1fr 1fr;
//     grid-gap: 8px;
//     border-bottom: 1px solid $black5;
//     padding: 10px 15px;

//     &:nth-child(n + 3):nth-child(odd) {
//       background: white;
//     }
//     &:last-child {
//       border-bottom: 1px solid transparent;
//     }

//     .comment {
//       grid-column: 1/3;
//     }
//     .store-name {
//       grid-column: 1/2;
//       grid-row: 1/2;
//       font-weight: 600;
//       color: $black1;
//       font-size: 14px;
//     }
//     .id {
//       grid-column: 1/3;
//       grid-row: 2/3;
//       &::before {
//         content: "Clicks Id: ";
//       }
//     }
//     .date {
//       justify-self: end;
//     }
//     .date,
//     .id {
//       font-size: 12px;
//       color: $black3;
//     }

//     &.col-5,
//     &.col-6 {
//       grid-template-columns: 1fr 1fr;
//       grid-template-rows: repeat(3, 1fr);
//       row-gap: 10px;

//       .cashback {
//         grid-column: 1/2;
//         grid-row: 2/3;
//         color: $black1;
//         font-weight: 700;
//         &::before {
//           content: "Cashback: ";
//           font-size: 12px;
//           font-weight: 500;
//           color: $black3;
//         }
//       }

//       .date {
//         grid-column: 2/3;
//       }

//       .order-id {
//         grid-column: 1/2;
//         grid-row: 3/4;
//         color: $black1;
//         font-weight: 700;
//         &::before {
//           content: "Order ID: ";
//           font-size: 12px;
//           font-weight: 500;
//           color: $black3;
//         }
//       }

//       .satus-def {
//         grid-column: 2/3;
//         grid-row: 1/2;
//         justify-self: end;
//         font-size: 12px;
//         font-weight: 600;
//       }
//       .view-btn-wr {
//         grid-column: 2/3;
//         grid-row: 2/3;
//         justify-self: end;
//       }
//       .view-btn {
//         width: 85px;
//         height: 28px;
//       }
//       .total-amount {
//         grid-column: 2/3;
//         grid-row: 2/3;
//         justify-self: end;
//         color: $black1;
//         font-weight: 700;
//         &::before {
//           content: "Total Amout: ";
//           font-size: 12px;
//           font-weight: 500;
//           color: $black3;
//         }
//       }
//     }
//   }
// }

.empty-data-wr {
  @include flexCenter;
  flex-direction: column;
  .img-wr {
    width: 230px;
    height: 230px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  p {
    text-align: center;
    font-size: 14px;
    line-height: 22px;
    color: $black3;
  }
}

// .amazon-links {
//   @include smoothAnimation;
//   padding: 0 20px;
//   @include _420 {
//     padding: 0 12px;
//   }
// }
// .amazon-card {
//   position: relative;
//   padding: 20px 0;
//   border-bottom: 1px solid $black5;

//   &:last-child {
//     border-bottom: none;
//     padding-bottom: 0;
//   }
//   p {
//     font-size: 14px;
//     font-weight: 500;
//     line-height: 17px;
//     color: $black3;
//   }
//   a {
//     font-size: 14px;
//     font-weight: 500;
//     line-height: 17px;
//   }
//   &__title-wr {
//     @include flexSpaceBetween;
//     margin-bottom: 8px;

//     img {
//       width: 20px;
//       height: 20px;
//       margin-left: 50px;
//       cursor: pointer;
//     }
//   }
//   &__title {
//     @include text-2;
//     font-weight: 600 !important;
//     color: $black1 !important;
//     margin-bottom: 12px;
//   }
//   &__field {
//     display: grid;
//     grid-template-columns: 90px 1fr 50px;
//     column-gap: 5px;
//     margin-bottom: 12px;
//     align-items: center;

//     .button::before {
//       content: "Price Alert: ";
//       position: absolute;
//       top: 0;
//       bottom: 0;
//       @include flexCenter;
//       left: -150%;
//       z-index: 2;
//       font-size: 12px;
//       font-weight: 500;
//       color: $black1;
//     }

//     a,
//     p:not(.label) {
//       white-space: nowrap;
//       overflow: hidden;
//       text-overflow: ellipsis;
//       padding-right: 10px;
//     }
//     .wr {
//       display: flex;
//     }

//     .wr {
//       display: flex;
//       align-items: center;
//     }
//     .copy-wr {
//       display: flex;
//       justify-content: center;
//       font-size: 12px;
//       font-weight: 600;
//       color: $red;
//       cursor: pointer;
//       img {
//         width: 16px;
//         height: 16px;
//         margin-right: 3px;
//       }
//     }

//     @include _420 {
//       grid-template-columns: 75px 1fr 35px;
//       row-gap: 8px;

//       p,
//       a {
//         font-size: 12px;
//         align-self: center;
//       }
//       .copy-wr {
//         flex-direction: column;
//         align-items: center;
//         font-size: 10px;
//         img {
//           height: 11px;
//           width: 11px;
//           margin-right: 0;
//         }
//       }
//     }
//   }

//   &__short-link-input-field {
//     display: grid;
//     grid-template-columns: 90px 1fr 240px;
//     column-gap: 5px;
//     margin-bottom: 12px;
//     align-items: center;
//     &.comment {
//       grid-template-columns: 90px 1fr;
//       margin-bottom: 0;
//       input {
//         max-width: 100%;
//       }
//       > div:not(.wr) p {
//         max-width: 100%;
//       }
//     }
//     .temp-input {
//       height: 32px;
//       padding: 8px 12px;
//       border-radius: 4px;
//       background: $black6;
//       width: 188px;
//       white-space: nowrap;
//       overflow: hidden;
//       text-overflow: ellipsis;
//       margin-left: 5px;
//     }

//     > div:not(.wr) {
//       display: flex;
//       align-items: center;
//       p,
//       a {
//         height: 32px;
//         padding: 8px 12px;
//         border-radius: 4px;
//         background: $black6;
//         width: 100%;
//         max-width: 300px;
//         white-space: nowrap;
//         overflow: hidden;
//         text-overflow: ellipsis;
//       }
//     }
//     input {
//       margin: 0;
//       height: 32px;
//       border-radius: 4px;
//       border: none;
//       background: $black6;
//       color: $black3;
//       font-size: 14px;
//       font-weight: 500;
//       padding: 8px 12px;
//       box-sizing: border-box;
//       max-width: 300px;

//       &:hover,
//       &:focus {
//         border-bottom: none !important;
//         box-shadow: none !important;
//       }
//     }

//     .buttons {
//       @include btn;
//       margin-left: 8px;
//       font-size: 14px;
//       line-height: 17px;
//       font-weight: 600;
//       color: $red;
//       span {
//         display: inline-block;
//         margin: 0 4px;
//       }
//     }

//     .wr {
//       display: flex;
//       align-items: center;
//     }

//     &.price-alert {
//       grid-template-columns: 90px 1fr;
//       margin-bottom: 30px;
//     }

//     .price-alert-input-wr {
//       input {
//         margin: 0 5px;
//       }
//     }
//     .price {
//       display: block;
//       margin-right: 40px;
//       color: $red;
//     }

//     @include _767 {
//       &.price-alert {
//         margin-bottom: 20px;
//       }
//       > .wr {
//         grid-column: 1/3;
//         margin-top: 8px;
//       }
//     }

//     @include _420 {
//       grid-template-columns: 74px 1fr;
//       row-gap: 8px;
//       &.comment {
//         grid-template-columns: 74px 1fr;
//       }
//       &.price-alert,
//       .price-alert-input-wr {
//         display: grid;
//         grid-template-columns: 76px 1fr;
//       }

//       .label {
//         font-size: 12px;
//       }
//       p {
//         font-size: 14px;
//         align-self: center;
//       }
//       span {
//         font-size: 14px;
//       }

//       .short-link-generator {
//         grid-column: 2/3;
//         font-size: 12px;
//       }
//       .buttons {
//         font-size: 12px;
//       }
//     }
//   }
//   &__mobile-btn-wr {
//     display: none;
//     img {
//       width: 20px;
//       height: 20px;
//     }

//     @include _767 {
//       @include flexSpaceBetween;
//       margin-top: 20px;
//       align-items: center;
//     }
//   }

//   @include _420 {
//     padding: 12px 0;
//   }
// }
