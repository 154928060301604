.cacheback-link-generator {
  position: relative;
  display: block;
  max-width: 1300px;
  width: 100%;
  margin: 20px auto;
  padding: 25px 32px 40px;
  border-radius: 10px;
  background: white;
  box-sizing: border-box;
  @include smoothAnimation;

  &__tabs {
    display: flex;
  }

  &__title {
    position: relative;
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 40px;
    margin-right: 40px;
    cursor: pointer;
    color: $black2;
    &.active {
      color: $black1;
      cursor: auto;

      &::after {
        content: "";
        position: absolute;
        top: 110%;
        left: 0;
        right: 0;
        margin: 0 auto;
        height: 4px;
        width: 30%;
        border-radius: 10px;
        background: $red;
      }
    }
    &:hover {
      color: $black1;
    }
    &:last-child {
      margin-right: 0;
    }

    &.open {
      img {
        transform: rotate(180deg);
      }
    }
    img {
      width: 22px;
      height: 22px;
      pointer-events: none;
    }
  }
  &__form {
    @include flexSpaceBetween;
    height: 100%;
    max-height: 360px;
    overflow: hidden;
    @include smoothAnimation;
    .input-form-wr,
    .output-results {
      flex: 1 1 auto;
    }
    .input-form-wr {
      max-width: 460px;
    }
    .generate-btn {
      @include btn;
      margin: 40px 30px;
      padding: 0 8px;
      height: 34px;
      min-width: 110px;
      background: white;
      color: $red;
      border-radius: 4px;
      border: 1px solid $red;
      font-size: 14px;
      font-weight: 600;
    }
    h2 {
      font-size: 16px;
      font-weight: 600;
      line-height: 19px;
      margin-bottom: 12px;
      span {
        color: $red;
        font-weight: 700;
      }
    }
    .notice {
      font-size: 12px;
      span {
        color: $red;
        font-weight: 700;
      }
    }
    .wr {
      position: relative;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .input-label-wr {
      position: relative;
      span {
        position: absolute;
        @include flexCenter;
        font-size: 14px;
        left: 5px;
        top: 0;
        bottom: 0;
      }
    }
    .textArea {
      padding: 12px 18px;
      background: $black6;
      resize: none;
      outline: none;
      border: none;
      height: 300px;
      width: 100%;
      box-sizing: border-box;
      margin: 0;
      border-radius: 6px;
      color: $black3;
      font-size: 14px;
      line-height: 17px;
      &:focus {
        border: none !important;
        box-shadow: none !important;
      }
      &.cad {
        height: 130px;
        margin-bottom: 16px;
      }
      &.mb-0 {
        margin-bottom: 0;
      }
      &.input-textArea {
        height: 35px;
        margin-bottom: 12px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      &.res-link {
        height: 150px;
      }
      &.short-link {
        height: 90px;
      }

      &::-webkit-scrollbar {
        width: 3px;
      }
    }
    .copy-wr {
      @include flexCenter;
      position: absolute;
      right: 0;
      bottom: 7px;
      padding: 10px 12px;
      cursor: pointer;
      span {
        color: $red;
        font-size: 14px;
        font-weight: 600;
      }
      img {
        width: 16px;
        height: 16px;
        margin-right: 3px;
      }
    }
  }

  @include _1100 {
    &__form {
      flex-direction: column;
      max-height: 100%;
      .input-form-wr {
        max-width: 100%;
      }
      .textArea {
        height: 200px;

        &.short-link {
          height: 100px;
        }
      }
    }
  }
  @include _767 {
    padding: 20px 20px 30px;
    width: 97%;
    #cbl {
      margin-bottom: 40px;
    }
    &__title {
      @include flexSpaceBetween;
      align-items: center;
      margin-bottom: 20px;
      &.active::after {
        display: none;
        content: none;
      }
    }
  }
  @include _420 {
    padding: 15px 10px 20px;
    &__title {
      font-size: 16px;
      line-height: 19px;
      margin-right: 0px;
    }
    &__form {
      h2 {
        font-size: 14px;
        line-height: 17px;
      }
      .textArea {
        height: 80px;
        &.cad {
          height: 80px;
        }
        &.res-link {
          height: 100px;
        }
        &.short-link {
          height: 50px;
        }
      }
      .generate-btn {
        margin: 20px auto;
      }
    }
  }
}

.blur {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  pointer-events: none;
  &.saving {
    animation: flash 2.5s ease-out forwards;
    pointer-events: all;
  }
}

@keyframes flash {
  0% {
    background: rgba(250, 250, 250, 0.3) 100%;
    backdrop-filter: blur(6px);
  }
  30% {
    background: rgba(250, 250, 250, 0.3) 100%;
    backdrop-filter: blur(5px);
  }
  70% {
    background: rgba(250, 250, 250, 0.3) 100%;
    backdrop-filter: blur(3px);
  }
  100% {
    background: rgba(250, 250, 250, 0.3) 100%;
    backdrop-filter: blur(2px);
  }
}

.share-btns {
  @include flexCenter;
  margin: 30px auto 0;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  p {
    font-size: 14px;
    font-weight: 500;
    color: $black2;
    margin-right: 15px;
  }
  img {
    display: block;
    width: 36px;
    height: 36px;
    margin-right: 20px;
    cursor: pointer;
    transition: all 0.3s;
    &:last-child {
      margin-right: 0;
    }

    &:hover {
      transform: scale(1.1);
    }
  }
  a {
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
  }
  @include _420 {
    margin: 15px auto 0;
  }
}
