@mixin btn {
  position: relative;
  @include flexCenter;
  text-align: center;
  border: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
}

@mixin overLay {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  height: 100vh;
  width: 100%;
  background: $black1;
  opacity: 0;
  z-index: 3;
  transition: opacity 0.2s ease-out;
}

@mixin smoothAnimation {
  opacity: 0;
  animation: smooth 0.3s ease forwards;
}

@keyframes smooth {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flexSpaceBetween {
  display: flex;
  justify-content: space-between;
}

@mixin flexStartCenter {
  display: flex;
  justify-content: center;
}

@mixin flexAlignCenter {
  display: flex;
  align-items: center;
}

@mixin text {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  white-space: normal;
}

@mixin text-1 {
  @include text;
  -webkit-line-clamp: 1;
}

@mixin text-2 {
  @include text;
  -webkit-line-clamp: 2;
}

@mixin text-3 {
  @include text;
  -webkit-line-clamp: 3;
}

@mixin _1660 {
  @media (max-width: 1660px) {
    @content;
  }
}

@mixin _1360 {
  @media (max-width: 1360px) {
    @content;
  }
}

@mixin _1160 {
  @media (max-width: 1160px) {
    @content;
  }
}

@mixin _1100 {
  @media (max-width: 1100px) {
    @content;
  }
}

@mixin _1060 {
  @media (max-width: 1060px) {
    @content;
  }
}

@mixin _900 {
  @media (max-width: 900px) {
    @content;
  }
}

@mixin _767 {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin _600 {
  @media (max-width: 600px) {
    @content;
  }
}

@mixin _420 {
  @media (max-width: 420px) {
    @content;
  }
}

@mixin _376 {
  @media (max-width: 376px) {
    @content;
  }
}

@mixin _320 {
  @media (max-width: 320px) {
    @content;
  }
}
