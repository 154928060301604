$contW: 1050px;
$imgW: 280px;
$formW: $contW - $imgW;
$switchAT: 1.2s;

$inputW: 340px;
$btnH: 40px;

$diffRatio: ($contW - $imgW) / $contW;

@mixin signUpActive {
  .auth-content.join & {
    @content;
  }
}

@mixin backGround {
  &:before {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: $contW;
    height: 100%;
    background-image: url("../img/bg.jpg");
    background-size: cover;
    transition: transform $switchAT ease-in-out;
  }
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      220deg,
      rgba(0, 0, 0, 0.7) 40%,
      rgba(153, 0, 0, 0.3) 100%
    );
    opacity: 0.85;
  }
}

.auth-content {
  overflow: hidden;
  position: relative;
  width: $contW;
  height: 635px;
  margin: 50px auto 100px;
  background: white;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  @include smoothAnimation;
}

.auth-form {
  position: relative;
  width: $formW;
  height: 100%;
  transition: transform $switchAT ease-in-out;
  padding: 50px 30px 0;
  h2 {
    width: 100%;
    font-size: 32px;
    color: $black1;
    text-align: center;
    margin: 0;
    padding-bottom: 15px;
  }
  .register-info {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    span {
      color: $red;
      font-weight: 600;
    }
  }

  .auth-title-wr {
    .fc-coin {
      width: 32px;
      height: 32px;
      margin-left: 15px;
    }
  }
  .input-field {
    display: block;
    width: $inputW;
    margin: 25px auto 0;
    input {
      height: 40px;
      background: white;
    }
    label {
      transform: translateY(-14px) scale(0.8);
      transform-origin: 0 0;
    }
    .img-wr {
      @include flexCenter;
      position: absolute;
      top: 15px;
      right: 1px;
      width: 18px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  @include _1160 {
    .auth-title-wr .fc-coin {
      width: 26px;
      height: 26px;
    }
    h2 {
      font-size: 26px;
    }
  }
}

.auth-sub-content {
  overflow: hidden;
  position: absolute;
  left: $formW;
  top: 0;
  width: $contW;
  height: 100%;
  padding-left: $imgW;
  background: white;
  transition: transform $switchAT ease-in-out;

  @include signUpActive {
    transform: translate3d($formW * -1, 0, 0);
  }
}

.img {
  overflow: hidden;
  z-index: 2;
  position: absolute;
  left: 0;
  top: 0;
  width: $imgW;
  height: 100%;
  padding-top: 360px;

  @include backGround;

  @include signUpActive {
    &:before {
      transform: translate3d($formW, 0, 0);
    }
  }

  &__text {
    z-index: 2;
    position: absolute;
    left: 0;
    top: 50px;
    width: 100%;
    padding: 0 20px;
    text-align: center;
    color: white;
    transition: transform $switchAT ease-in-out;
    font-weight: 500;

    h2 {
      margin-bottom: 10px;
      font-weight: 600;
      color: white;
    }

    p {
      font-size: 14px;
      line-height: 1.5;
    }

    &.m--up {
      @include signUpActive {
        transform: translateX($imgW * 2);
      }
    }

    &.m--in {
      transform: translateX($imgW * -2);

      @include signUpActive {
        transform: translateX(0);
      }
    }
  }
  &__btn {
    overflow: hidden;
    z-index: 2;
    position: relative;
    width: 130px;
    height: $btnH;
    margin: 0 auto;
    background: transparent;
    color: white;
    text-transform: uppercase;
    font-size: 16px;
    cursor: pointer;
    transition: transform, 0.2s ease-out;
    opacity: 0.97;

    &:hover {
      transform: scale(1.05);
      opacity: 1;
    }

    &:after {
      content: "";
      z-index: 2;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border: 2px solid white;
      border-radius: 4px;
    }

    span {
      position: absolute;
      left: 0;
      top: 0;
      @include flexCenter;
      width: 100%;
      height: 100%;
      transition: transform $switchAT;

      &.m--in {
        transform: translateY($btnH * -2);

        @include signUpActive {
          transform: translateY(0);
        }
      }

      &.m--up {
        @include signUpActive {
          transform: translateY($btnH * 2);
        }
      }
    }
  }
}
.mobile-btn {
  display: none;
}

.forgot-pass {
  display: block;
  width: 100%;
  margin-top: 10px;
  text-align: right;

  a {
    color: $black2;
    font-size: 12px;
    font-weight: 500;
    text-decoration: underline;
    &:hover {
      color: $black1;
    }
  }
}

.auth-btn {
  @include btn;
  width: $inputW;
  height: $btnH;
  border-radius: 4px;
  color: white;
  font-size: 18px;
  font-weight: 500;
  background: $red;
  margin: 20px auto;
  &:disabled {
    cursor: unset;
    background: $red-hover !important;
    opacity: 0.5;
  }
  &:hover {
    background: $red-hover;
  }
}
.social-wr {
  position: relative;
  width: $inputW;
  margin: 85px auto 0px;
  .social-separator {
    position: absolute;
    top: -55px;
    @include flexStartCenter;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    color: $black3;
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      background: $black3;
      height: 1px;
      width: 43%;
      opacity: 0.6;
    }
    &::before {
      left: 0;
    }
    &::after {
      right: 0;
    }
  }
  .social-btn-wr {
    @include flexAlignCenter;
    p {
      font-size: 14px;
      font-weight: 600;
      margin-right: 20px;
      color: $black2;
    }
  }
  .google,
  .facebook {
    @include flexCenter;
    width: $btnH;
    height: $btnH;

    transition: transform, 0.2s ease-out;
    .img-wr {
      width: 100%;
      height: 100%;
      img {
        width: $btnH;
        height: $btnH;
      }
    }
    &:hover {
      transform: scale(1.1);
    }
  }
  .google {
    margin-right: 35px;
  }

  .auth-policy-wr {
    margin-top: 25px;
    font-size: 12px;
    font-weight: 500;
    color: $black2;
    a {
      color: $black2;
      text-decoration: underline;
    }
  }
}

.sign-in {
  transition-timing-function: ease-out;

  @include signUpActive {
    transition-timing-function: ease-in-out;
    transition-duration: $switchAT;
    transform: translate3d($formW, 0, 0);
  }
}

.sign-up {
  transform: translate3d($contW * -1, 0, 0);

  @include signUpActive {
    transform: translate3d(0, 0, 0);
  }
}

@media screen and (max-width: 1160px) {
  $contW: 850px;
  $imgW: 270px;
  $formW: $contW - $imgW;
  .auth-content {
    width: $contW;
    height: 580px;
  }
  .auth-form {
    width: $formW;
  }
  .auth-sub-content {
    left: $formW;
    width: $contW;
    padding-left: $imgW;

    @include signUpActive {
      transform: translate3d($formW * -1, 0, 0);
    }
  }

  .img {
    width: $imgW;
  }

  .sign-in {
    @include signUpActive {
      transform: translate3d($formW, 0, 0);
    }
  }
  .sign-up {
    transform: translate3d($contW * -1, 0, 0);
  }
}

@media screen and (max-width: 960px) {
  $contW: 680px;
  $imgW: 240px;
  $formW: $contW - $imgW;
  .auth-content {
    width: $contW;
  }
  .auth-form {
    width: $formW;
  }
  .auth-sub-content {
    left: $formW;
    width: $contW;
    padding-left: $imgW;

    @include signUpActive {
      transform: translate3d($formW * -1, 0, 0);
    }
  }

  .img {
    width: $imgW;
  }

  .sign-in {
    @include signUpActive {
      transform: translate3d($formW, 0, 0);
    }
  }
  .sign-up {
    transform: translate3d($contW * -1, 0, 0);
  }
}

@media screen and (max-width: 767px) {
  $contW: 600px;
  $imgW: 200px;
  $formW: $contW - $imgW;
  .auth-content {
    width: $contW;
  }
  .auth-form {
    width: $formW;
  }
  .auth-sub-content {
    left: $formW;
    width: $contW;
    padding-left: $imgW;

    @include signUpActive {
      transform: translate3d($formW * -1, 0, 0);
    }
  }

  .img {
    width: $imgW;
    &__text {
      padding: 0 15px;
    }
  }

  .sign-in {
    @include signUpActive {
      transform: translate3d($formW, 0, 0);
    }
  }
  .sign-up {
    transform: translate3d($contW * -1, 0, 0);
  }
}

@media screen and (max-width: 660px) {
  .auth-content {
    width: 100%;
    margin: 0;
    height: 100vh;

    @include backGround;
    &::after {
      background: linear-gradient(
        220deg,
        rgba(0, 0, 0, 0.7) 60%,
        rgba(153, 0, 0, 0.4) 100%
      );
    }
  }
  .auth-form {
    width: 100%;
    z-index: 2;
    padding: 50px 15px 0;

    h2 {
      color: white;
      padding-bottom: 5px;
    }
    .register-info {
      color: white;
    }
    .input-field {
      input {
        background: transparent;
        color: white;
        &:focus {
          border-bottom: 1px solid white;
          box-shadow: 0 1px 0 0 white;
        }
        &:not([type]):focus:not([readonly]) + label {
          color: white !important;
        }
        &:-webkit-autofill {
          -webkit-text-fill-color: white !important;
          color: white !important;
        }
      }
      > label {
        color: white !important;
      }
    }
  }
  .forgot-pass a {
    color: white;
  }
  .social-wr {
    .social-separator {
      color: white;
      &::before,
      &::after {
        background: white;
        opacity: 0.6;
      }
    }
    .auth-policy-wr {
      color: white;
      a {
        color: white;
      }
    }
    .social-btn-wr p {
      color: white;
    }
  }
  .auth-sub-content {
    width: 100%;
    top: 0;
    left: 100%;
    padding-left: 0;
    z-index: 2;
    background: transparent;
    @include signUpActive {
      transform: translate3d(-100%, 0, 0);
    }
  }
  .sign-in {
    transition-timing-function: ease-out;
    @include signUpActive {
      transform: translate3d(-150%, 0, 0);
    }
  }
  .sign-up {
    transition-timing-function: ease-out;
    transform: translate3d(50%, 0, 0);
  }

  .mobile-btn {
    display: block;
    overflow: hidden;
    z-index: 2;
    position: relative;
    height: $btnH;
    margin: 30px auto 0px;
    background: transparent;
    color: white;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    outline: none;

    > span {
      position: absolute;
      left: 0;
      top: 0;
      @include flexCenter;
      width: 100%;
      height: 100%;
      transition: transform;
      > span {
        text-decoration: underline;
      }

      &.m--in {
        transform: translateX(150%);

        @include signUpActive {
          transform: translateX(0);
        }
      }

      &.m--up {
        @include signUpActive {
          transform: translateX(150%);
        }
      }
    }
  }
  .img {
    display: none;
  }
}

@media screen and (max-width: 420px) {
  .auth-form {
    padding: 35px 10px 0;
    h2 {
      font-size: 24px;
    }
  }
}

@media screen and (max-width: 375px) {
  $inputW: 300px;

  .auth-form .input-field {
    width: $inputW;
  }

  .social-wr {
    width: $inputW;
  }
  .auth-btn {
    width: $inputW;
  }
  .social-wr .social-btn-wr {
    p {
      font-size: 13px;
      margin-right: 10px;
    }
    .google {
      margin-right: 25px;
    }
  }
}

@media screen and (max-width: 340px) {
  $inputW: 300px;
  .auth-content {
    height: 640px;
  }
  .auth-form .input-field {
    width: $inputW;
  }

  .social-wr {
    width: $inputW;
  }
  .auth-btn {
    width: $inputW;
  }
}

@media screen and (max-width: 280px) {
  $inputW: 250px;

  .auth-form .input-field {
    width: $inputW;
  }

  .social-wr {
    width: $inputW;
  }
  .auth-btn {
    width: $inputW;
  }
}
