@import "./common/variables.scss";
@import "./common/resets.scss";
@import "./common/mixin.scss";
@import "./common/common-styles.scss";
@import "./home-page.scss";
@import "./auth-page.scss";
@import "./account-page.scss";
@import "./forget-page.scss";
@import "./widgets/paginator.scss";
@import "./widgets/loader.scss";
@import "./404.scss";

#root {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow: auto;
  background: white;
}

.main-content-wr {
  flex: 1 0 auto;
  width: 100%;
  overflow: hidden;
  background: $black6;
}

footer {
  flex: 0 0 auto;
  background: white;
}
